.expense-panel {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: get-color('greyscale', 'white');
  border: 1px solid get-color('forms', 'outline');
  padding: 0.7em;

  .expense-top-container {
    @include flex;
    @media screen and (max-width: 580px) {
      @include flex-column;
    }
    .expense-panel-header {
      @include flex;
      align-items: center;
      min-width: 18em;

      .expense-panel-header-image {
        margin-right: 1em;
        margin-left: 1em;
        img {
          height: 30px;
        }
      }
  
      .expense-panel-header-title-container {
        .expense-panel-title {
          font-size: large;
          font-weight: bold;
        }
  
        .expense-panel-subtitle {
          color: get-color('greyscale', 'grey2');
          font-size: small;
        }
      }
    }

    .expense-panel-amount-and-frequency {
      flex: auto;
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 1em;
      @media screen and (max-width: 400px) {
        grid-template-columns: auto;
      }

      #expense-panel-amount {
        min-width: 8em;
      }

      #expense-panel-frequency {
        min-width: 8em;
      }
      
      select:disabled {
        background-color: get-color('greyscale', 'grey7');
      }
    }
  }
}