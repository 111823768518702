@font-face {
  font-family: 'Baloo Paaji 2';
  src: url('../../fonts/BalooPaaji2-VariableFont_wght.ttf') format('truetype');
  font-weight: 500;
}
@font-face{
  font-family: 'Baloo Paaji 2 Bold';
  src: url('../../fonts/BalooPaaji2-VariableFont_wght.ttf') format('truetype');
  font-weight: 700;
}
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700');
body {
  font-family: $bodyFont;
  font-size: 14px;
  line-height: $bodyLineHeight;
  margin: 0;
  @include breakpoint(medium) {
    font-size: 16px;
  }
}

p,
ul,
ol,
blockquote {
  @include v-space(margin-bottom, 0.75);
  @include v-space(margin-top, 0.75);
}

li {
  @include v-space(margin-bottom, 0.5);
  &:last-of-type {
    margin-bottom: 0;
  }
}

strong {
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include v-space(margin-bottom, 0.5);
  @include v-space(margin-top, 0.5);
  color: get-color('greyscale', 'black');
  font-weight: normal;
  text-align: center;
}

h1 {
  @include v-space(margin-bottom, 0.5);
  @include v-space(margin-top, 0.5);
  small {
    font-family: $baloo-paaji;
    font-size: 1.2rem;
    font-weight: 700;
    @include breakpoint(medium) {
      font-size: 1.4rem;
    }
  }
}

h2 {
  letter-spacing: -0.48px;
}

.text {
  &__lead, &__lead__bold {
    @include v-space(margin-bottom, 0.5);
    @include v-space(margin-top, 0.5);
    color: get-color('grey-scale', 'black');
    text-align: center;
    strong {
      font-family: $baloo-paaji;
    }
    ul {
      display: inline-block;
      margin: 0;
    }
    li {
      margin-bottom: 0;
      text-align: left;
      width: auto;
    }
  }

  &__lead__bold {
    font-weight: bold;
  }
  
  &__label {
    color: get-color('greyscale', 'black');
    font-weight: 700;
  }
  &-center {
    text-align: center;
  }
  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
  }
}

.fa-icon-primary {
  &.fa-primary {
    fill: get-color('primary')
  }
  &.fa-secondary {
    fill: get-color('greyscale', 'grey4')
  }
}