.car-loan-form-row {
  display: block;
  @include breakpoint(medium) {
    display: flex;
    .form__row {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      width: 100%;
      margin-right: 1rem;
      &:last-child {
        margin-right: 0rem;
      }
      .form__label:only-child {
        margin-bottom: rem-calc(19)
      }
    }
    .form__row.multi-line-error{
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      & .form__error-message{
        align-self: flex-start;
      }
    }
  }
}

#car-loan-complete-card {
  h4 {
    font-size: 1.1rem;
    font-weight: 700;
    padding: 0;
  }
  ul {
    text-align: center;
    list-style: none;
    padding: 0;
    li {
      padding: 0;
      margin: rem-calc(5) 0;
    }
    strong {
      font-weight: 700;
    }
  }
}

.terms-modal {
  .modal {
    max-width: 80%;
  }
}