@import 'transaction-field/transaction-field';

.income-item-panel {
  @include v-space(padding, 0.5);
  @include v-space(margin-top, 0.5);
  @include v-space(margin-bottom, 0.5);
  background-color: get-color('greyscale', 'white');
  border: 1px solid get-color('forms', 'outline');

  .income-item-amount-frequency-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 1em;

    @media only screen and (max-width: 500px) {
      grid-template-columns: auto;
    }
  }
}

.alert-icon {
  color: get-color('forms', 'information');
  @include breakpoint(small) {
    margin-right: 0.5rem;
  }
}