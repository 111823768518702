.loanoverview {
  h4 {
    font-size: 1.1rem;
    font-weight: 700;
    padding: 0;
  }
  ul {
    text-align: center;
    list-style: none;
    padding: 0;
    li {
      padding: 0;
      margin: rem-calc(5) 0;
    }
    strong {
      font-weight: 700;
    }
  }
}

.text__faq {
  text-align: center;
  strong {
    font-weight: 700;
  }
}

.application__progress {
  @include v-space(margin-top, 1);
  @include v-space(margin-bottom, 1);
  text-align: center;
  ul {
    @include list-reset;
    counter-reset: step-counter;
    display: inline-block;
  }
  &-item {
    color: get-color('greyscale', 'grey4');
    font-size: rem-calc(12);
    font-weight: 700;
    margin-bottom: 0;
    text-align: left;
    @include breakpoint(medium) {
      display: inline-block;
      padding: 0 rem-calc(10);
      text-align: center;
      vertical-align: top;
      width: 20%;
    }
    .item-step {
      background-color: get-color('greyscale', 'grey6');
      border-radius: 50%;
      color: get-color('greyscale', 'black');
      display: inline-block;
      font-style: normal;
      height: rem-calc(32);
      line-height: rem-calc(32);
      margin-right: rem-calc(22);
      position: relative;
      text-align: center;
      vertical-align: middle;
      width: rem-calc(32);
      @include breakpoint(medium) {
        display: block;
        margin: 0 auto rem-calc(7);
      }

      &.counter {
        &::before {
          content: counter(step-counter);
          counter-increment: step-counter;
        }  
      }
      
      &::after {
        background-color: get-color('greyscale', 'grey6');
        content: '';
        display: block;
        height: rem-calc(20);
        left: 0;
        margin-left: calc(50% - 1px);
        position: absolute;
        top: 100%;
        width: rem-calc(2);
        @include breakpoint(medium) {
          height: rem-calc(2);
          width: 250%;
          left: 100%;
          margin: 0;
          top: calc(50% - 1px);
        }
        @include breakpoint(large) {
          width: 300%;
        }
      }
    }
    &.approved {
      color: darken(get-color('forms', 'success'), 5%);
      .item-step {
        background-color: darken(get-color('forms', 'success'), 5%);
        color: get-color('greyscale', 'white');
        font-size: rem-calc(20);
        &::after {
          background-color: darken(get-color('forms', 'success'), 5%);
        }
      }
    }
    &.current {
      color: get-color('greyscale', 'black');
      .item-step {
        background-color: get-color('greyscale', 'black');
        color: get-color('greyscale', 'white');
      }
    }
    &:last-child {
      .item-step::after {
        display: none;
      }
    }
  }
  .complete-icon {
    left: 3px;
    top: 4px;
    @include breakpoint(medium) {
      left: calc(50% - 13px);
    }

    position: absolute;
    width: 2em;
    height: 2em;
    color: white;
  }
}
