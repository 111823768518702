// == tracker status module
.tracker-status {
  .card {
    @include g-unit(padding-top, 0.5);
    h2 {
      @extend h1;
      .label {
        display: block;
        font-weight: 300;
      }
    }
    p {
      @extend .text__lead;
      white-space: pre-line;
    }
    .button {
      @include g-unit(margin-top, 0.5);
      width: 100%;
      @include breakpoint(medium) {
        max-width: 400px;
      }
    }
  }
}

// == tracker text

.tracker-text {
  margin-bottom: rem-calc(20);
  p:last-of-type {
    margin-bottom: 0;
  }
  button {
    font-size: rem-calc(14);
    @include breakpoint(medium) {
      font-size: rem-calc(18);
    }
  }
}
