//expense modules
//expense summary
//not sure where this is???
.expense-summary {
  @extend .summary-box;
  li {
    border-bottom: 1px solid get-color('greyscale', 'grey7');
    padding: rem-calc(10) 0;
    &:first-of-type {
      padding-top: 0;
    }
    .label {
      font-size: rem-calc(14);
      @include breakpoint(medium) {
        font-size: rem-calc(18);
      }
    }
    small {
      display: block;
      font-size: rem-calc(10);
      @include breakpoint(medium) {
        font-size: rem-calc(12);
      }
    }
    .value {
      font-size: rem-calc(18);
      font-weight: 600;
      @include breakpoint(medium) {
        font-size: rem-calc(32);
      }
    }
  }
}
