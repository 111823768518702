fieldset {
  border: 0;
  margin: 0;
  padding: 0;
  min-width: 0; //override browser defauly weirdness
}

//Individual Form Elements

//Form Classes
.form {
  &__row {
    @include v-space(padding-top, 0.5);
    @include v-space(padding-bottom, 0.5);
    margin: 0;
    &-list {
      list-style: none;
      margin: 0;
      padding-left: 0;
      display: inline-block;
    }
  }
  &__label,
  &__legend {
    color: get-color('greyscale', 'grey2');
    display: block;
    font-size: rem-calc(14);
    font-weight: 400;
    width: 100%;
    &-header {
      color: get-color('greyscale', 'black');
      font-size: rem-calc(16);
      text-align: center;
      font-weight: 700;
      > input {
        @include v-space(margin-top, 1);
      }
    } //hide labels
    &-hide {
      display: block;
      height: 0;
      position: absolute;
      visibility: hidden;
    }
  }
  &__input::-ms-clear {
    display: none;
  }
  &__input,
  &__select,
  &__textarea {
    @extend .large !optional;
    border: 1px solid get-color('forms', 'outline');
    border-radius: rem-calc(3);
    color: get-color('greyscale', 'black');
    display: block;
    font-size: rem-calc(16);
    padding: $inputPadding;
    width: 100%;
    &:focus {
      @include focus;
    }
    &:disabled,
    &[disabled] {
      background: get-color('greyscale', 'grey7');
    }
    &-small {
      margin-left: auto;
      margin-right: auto;
      max-width: 192px;
      width: 65%;
    }
    &-security {
      letter-spacing: rem-calc(5);
      text-align: center;
    }
  }
  &__select {
    appearance: none;
    background: transparent;
    position: relative;
    z-index: 1; // Remove IE select dropdown arrow
    &::-ms-expand {
      display: none;
    }
  }
  &__select-wrap {
    position: relative;
    .form__select {
      background: url('../../../images/arrow-down.svg');
      background-size: 15px;
      background-position-x: calc(100% - 15px);
      background-position-y: center;
      background-repeat: no-repeat;
      background-color: get-color('greyscale', 'white');
    }
  }
  &__label {
    &-pill {
      @include border($color: get-color('forms', 'outline'), $radius: rem-calc(3));
      @include transition;
      background-color: get-color('greyscale', 'white');
      color: get-color('greyscale', 'black');
      display: block;
      font-size: rem-calc(16);
      min-width: rem-calc(60);
      padding: rem-calc(10) rem-calc(12);
      text-align: center;
      &:hover {
        cursor: pointer;
        background-color: get-color('primary', 'light');
        border-color: get-color('primary', 'light');
      }
      &:focus {
        outline: none;
      }
    }// radio and checkboxes
    &-radio,
    &-checkbox {
      color: get-color('greyscale', 'black');
      padding: rem-calc(10) 0 rem-calc(10) rem-calc(45);
      position: relative;
      cursor: pointer;
      font-size: rem-calc(16);
      &::before {
        background: get-color('greyscale', 'white');
        border: 1px solid get-color('forms', 'outline');
        content: '';
        display: block;
        position: absolute;
        left: 0;
        height: 24px;
        width: 24px;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
    &-radio {
      &::before {
        border-radius: 50%;
      }
    }
    &-emphasized {
      text-align: center;
      font-weight: 800;
      line-height: 3;
      color: #000;
    }
  }
  &__input-radio,
  &__input-checkbox {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    .label:focus {
      outline: none;
    }
    &:focus + label::before,
    &:checked:focus + label::before {
      @include focus;
    }
    &:checked + label::before {       
      background-color: get-color('primary');
    }
  }
  &__input-checkbox {
    &:checked + label::before {
      background-image: url('../../../images/checkbox-check.svg');
      background-size: 16px;
    }
  }
  &__input-radio {
    &:checked ~ .form__label-pill {
      background-color: get-color('primary');
      border-color: get-color('primary');
      color: get-color('system');
      &::before {
        display: none;
      }
    }
    &:focus ~ .form__label-pill {
      @include focus;
    }
    &:checked + label::before {
      background-image: url('../../../images/radio-dot.svg');
      background-size: 10px;
    }
  }
  &__input-code {
    text-align: center;
    letter-spacing: rem-calc(6);
  } // help text
  &__help {
    &-text {
      line-height: 1.5;
      font-size: 13px;
      font-style: italic;
      color: get-color('greyscale', 'grey1');
      margin: rem-calc(5) 0 0;
    }
  } // error messaging
  &__error {
    &-message {
      color: get-color('forms', 'error');
      font-size: rem-calc(13);
      display: block;
    }
    .form__input,
    .form__select,
    .form__textarea,
    .form__textarea,
    .form__label-pill {
      @include form-error;
      &:focus {
        @include focus-error;
      }
    }
    .select-box-with-search {
      .select__control {
        @include form-error;
        &--is-focused {
          @include focus-error;
        }
      }
    }

  } //radio pills
  &__group {
    &-pills {
      @include clearfix;
      li {
        float: left;
        margin-bottom: 0;
        margin-right: rem-calc(20);
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &-4, &-2 {
      display: block;
    }

    &-4 {
      li {
        width: calc(25% - #{rem-calc(16)});
      }
    }

    &-2 {
      li {
        width: calc(50% - #{rem-calc(16)});
      }
    }
  }
  &__label-password {
    position: relative;
    .password-toggle {
      background-color: get-color('greyscale', 'white');
      bottom: 1px;
      color: #747678;
      font-size: rem-calc(12);
      line-height: rem-calc(44);
      padding: 0 15px;
      position: absolute;
      right: 1px;
      text-transform: uppercase;
      z-index: 1;
    }
  }
  &__input-password {
    position: relative;
    +.toggle {
      bottom: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  &__autocomplete {
    div {
      position: relative;
      input {
        background: url('../../../icons/search.svg') center right no-repeat get-color('greyscale', 'white');
        background-position-x: calc(100% - 15px);
        background-size: 15px 15px;
        border-radius: rem-calc(3);
        padding-right: 35px;
      }

      .menu {
        @include border($color: get-color('forms', 'outline'));
        display: block;
        border-radius: rem-calc(3);
        background-color: get-color('greyscale', 'white');
        position: absolute;
        top: calc(100% + 5px);
        width: 100%;
        z-index: 1;
        max-height: 30vh;
        overflow-y: scroll;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.16);

        .item {
          display: block;
          padding: rem-calc(10);
          color: get-color('greyscale', 'grey1');
          &-highlighted,
          &:hover {
            background-color: get-color('primary', 'light');
            color: get-color('greyscale', 'black');
            cursor: pointer;
          }
          + .item {
            border-top: 1px solid get-color('forms', 'outline');
          }
        }
      }
      ul.menu {
        list-style: none;
        padding: 0;
        margin-top: 0;
      }
    }
  }
  &__set {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: space-between;
  }
}

//Input Icons
$input-icon-padding: 35px;
$input-icon-offset: 9px;

.input-with-icon {
  position: relative;
  
  &.input-with-icon-left {
    input {
      padding-left: $input-icon-padding;
    }
  }

  &.input-with-icon-right {
    input {
      padding-right: $input-icon-padding;
    }
  }
  

  svg.input-icon-left, svg.input-icon-right {
    position: absolute;
    bottom: 15px;
    font-size: 18px;
  }

  svg.input-icon-left {
    left: $input-icon-offset;
  }

  svg.input-icon-right {
    right: $input-icon-offset;
  }
}

//form errorMessage
// T&C error
.form__label-checkbox + .form__error-message {
  margin-top: -15px;
  margin-bottom: 24px;
  margin-left: 40px;
}

//form label
.form__row > .text__label {
  margin-top: 0;
}

.form__label {
  &.text__label {
    color: get-color('greyscale', 'black');
    font-weight: 700;
    > input {
      @include v-space(margin-top, 1)
    }
  }
}

select {
  &:disabled {
    background-color: get-color('greyscale', 'grey7') !important; 
    opacity: 0.8;
  }
}

//File Upload

  .file-upload-container {
    margin-top: 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    border-width: 2px;
    border-radius: 2px;
    outline-color: get-color('primary', 'dark');
    outline-style: dashed;
    background-color: #FAFAFA;
    transition: all .24s ease-in-out;
    min-height: 200px;
    &.drag-active {
      outline-offset: -1rem;
    }

    &.error-on-drag {
      outline-color: get-color('forms', 'error');
    }

    &.success-on-drag {
      outline-color: get-color('forms', 'success');
    }
  }

  .form-info-tooltip-button {
    @include clickable;
    @include hover-grow(1.2);
  }