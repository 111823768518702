.login {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  &__heading {
    flex: 1 1 auto;
    align-self: center;
    text-align: left;
    margin: 0 rem-calc(10) 0 0;
    &-logo {
      flex: 0 1 auto;
      max-height: rem-calc(55);
      max-width: rem-calc(130);
      margin-right: rem-calc(15);
      display: none;
      align-self: start;
      @include breakpoint(medium) {
        display: block;
      }
    }
    &-text {
      flex-grow: 1;
      h2, 
      p {
        text-align: left;
        margin: 0;
      }
      h2 {
        font-size: 18px;
      }
      p {
        font-size: 13px;
      }
    }
    &-security {
      flex: 0 1 auto;
      height: rem-calc(50);
      align-self: start;
      margin-left: rem-calc(15);
    }
  }
  &-small {
    > .login__heading-logo,
    > .login__heading-security {
      height: rem-calc(45);
    }
  }
  &__progress {
    ul {
      @include list-reset;
      display: inline-block;
    }
    li {
      @include v-space(margin-bottom, 0.25);
      font-size: 16px;
      text-align: left;
    }
    .login__progress-icon {
      display: inline-block;
      margin-right: 25px;
      vertical-align: middle;
      width: 20px;
      &.loading {
        animation: loading-spinner 1s linear;
        animation-iteration-count: infinite;
      }
    }
  }
  & + &__progress {
    @include v-space(margin-top, 1);
  }
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
