/// progress bar
// base size of the progress bar
$progress-baseunit: rem-calc(4);
//create step positions
$progress-steps: 5;
$progresswidth: 20%;

// progess bar size and structure
@mixin progress-bar {
  @include clearfix;
  @include v-space(padding-top, 0.5);
  @include v-space(margin-left, 0.5);
  @include v-space(margin-right, 0.5);
  @include breakpoint(medium) {
    margin-left: auto;
    margin-right: auto;
    max-width: rem-calc(640);
  }
  
  &__bar {    
    &-meter {
      @include clearfix;
      background-color: #dbdbdb;
      border-radius: $progress-baseunit;
      height: $progress-baseunit * 2;
      margin: $progress-baseunit * 1.5 0;
      @include breakpoint(medium) {
        border-radius: $progress-baseunit * 4;
        height: $progress-baseunit * 4;
        margin: $progress-baseunit * 3 0;
      }
    }

    &-gutter {
      margin-left: $progress-baseunit * 0.5;
      margin-right: $progress-baseunit + $progress-baseunit * 0.5;
      position: relative;
      @include breakpoint(medium) {
        margin-left: $progress-baseunit;
        margin-right: $progress-baseunit * 2 + $progress-baseunit;
      }
    }

    &-step {
      background-color: get-color('greyscale', 'white');
      border-radius: 50%;
      display: block;
      height: $progress-baseunit;
      position: absolute;
      top: $progress-baseunit * 0.5;
      width: $progress-baseunit;

      @include breakpoint(medium) {
        height: $progress-baseunit * 2;
        top: $progress-baseunit;
        width: $progress-baseunit * 2;
      }
      
      &.complete {
        background-color: get-color('greyscale', 'black');
      }

      &.active {
        background-color: get-color('primary');
        height: $progress-baseunit * 7;
        margin-left: -#{$progress-baseunit * 2.8};
        margin-top: -#{$progress-baseunit * 2.8};
        width: $progress-baseunit * 7;
        @include breakpoint(medium) {
          height: $progress-baseunit * 10;
          margin-left: -#{$progress-baseunit * 4};
          margin-top: -#{$progress-baseunit * 4};
          width: $progress-baseunit * 10;

          i {
            width: rem-calc(25) !important;
          }
        }

        i {
          width: rem-calc(18);
          opacity: 1;
        }
      }

      i {
        @include absolute-center;
        width: rem-calc(28);
        opacity: 0;
        transition: $transitionTime $transitionEase 300ms;
        display: block;
      }
    } //bar progress

    &-progress {
      @include transition;
      background-color: get-color('primary');
      border-radius: $progress-baseunit;
      height: $progress-baseunit * 2;
      
      @include breakpoint(medium) {
        border-radius: $progress-baseunit * 2;
        height: $progress-baseunit * 4;
      }
    }
  }
}

//progress bar
.progress {
  @include progress-bar;

  @for $step from 1 through 6 {
    .step#{$step} {
      left: calc(#{$progresswidth * $step - 20});
    }
  }

  &__label {
    font-family: $baloo-paaji;
    font-size: rem-calc(12);
    float: right;
    line-height: $progress-baseunit * 5;
    margin-bottom: 0;
    text-align: center;
    @include breakpoint(medium) {
      @include v-space (margin-bottom, 0.5);
      font-size: rem-calc(14);
      float: none;
    }

    .label {
      display: none;
      @include breakpoint(medium) {
        display: inline-block;
        margin-left: rem-calc(5);
      }
    }
  }

  &__bar {
    float: left;
    width: calc(100% - 40px);
    @include breakpoint(medium) {
      float: none;
      width: 100%;
    }
  }
}

.progress-bar-options {
  text-align: right;
  font-family: $openSans;
  position: relative;

  .progress-bar-options-button-container {
    font-size: rem-calc(18);
    padding: rem-calc(15);
    padding-left: 0.5rem;
    line-height: 1.5;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    button {
      font-family: $baloo-paaji-bold;
      margin-left: rem-calc(15);
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
  }
}

.progress-bar-options-overlay-container {
  transform: translateY(rem-calc(-15));
  transition: 0.5s opacity cubic-bezier(0.19, 1, 0.22, 1), 0.5s transform cubic-bezier(0.19, 1, 0.22, 1);
  max-width: rem-calc(250);
  width: 100%;
  z-index: 99;

  .progress-bar-options-content {
    background: get-color('greyscale', 'white');
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.16);
    padding: 0;
    list-style: none;
    text-align: left;
    z-index: 100;
    border: 1px solid get-color('greyscale', 'grey7');
    border-radius: rem-calc(4);
    transform: translateY(rem-calc(-15));
    transition: 0.5s opacity cubic-bezier(0.19, 1, 0.22, 1), 0.5s transform cubic-bezier(0.19, 1, 0.22, 1);

    .progress-bar-options-item {
      padding: 0;
      margin-bottom: 0;
      a {
        padding: rem-calc(10);
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        text-decoration: none;
        color: black;

        &.link-disabled {
          background-color: get-color('greyscale', 'grey7');
        }
        &:hover {
          background-color: get-color('greyscale', 'grey7');
          opacity: 0.85;
          transition: $transitionTime $transitionEase;
        }

        .progress-complete {
          color: green;
        }
      }
    }
  }
}