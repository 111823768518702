.select-box-with-search {
  .select__control {
    height: 48px;

    &--is-focused, &--menu-is-open {
      box-shadow: 0 0 0 1px get-color('primary', 'dark');
    }
    &--is-focused {
      border-color: get-color('primary');
    }

    &--menu-is-open {
      &:hover {
        border-color: get-color('primary');
      }
    }
    .select__value-container {
      outline-color: get-color('primary', 'dark');
    }
  }

  .select__option {
    &--is-selected {
      background-color: get-color('primary', 'dark');
    }
    &:hover, --is-focused {
      background-color: get-color('primary', 'light');
      color:black;
    }
  }
}