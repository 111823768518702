

.list-item {
  @include v-space(padding, 0.3);
  width: 100%;
  border: solid 1px black;
  transition: background-color $transitionTime $transitionEase;

  &.disabled {
    pointer-events: none;
    background-color: get-color('greyscale', 'grey5');
  }
  .list-item-main {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
  }
  
  &:not(:last-child) {
    @include v-space(margin-bottom, 0.3);
  }

  &.clickable {

    &:hover {
      background-color: get-color('primary', 'light');
    }

    @include clickable;
    @include hover-grow(1.05);
  }

  .list-item-icon {
    width: 40px;
    height: 40px;
    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 1.25rem;
    align-items: center;
    @include v-space(margin-right, 0.5);
  }

  .list-item-content-container, .list-item-text-container {
    flex: 1 1 auto;
  }
   
  .list-item-text-container {
    min-width: 0;
    @include v-space(margin-bottom, 0.5);
    @include v-space(margin-top, 0.5);
  }

  .list-item-primary-text {
    overflow-wrap: break-word;
  }

  .list-item-secondary-text {
    font-size: 0.875rem;
    overflow-wrap: break-word;
  }

  .list-item-actions {
    @include v-space(margin-left, 0.5);
    a {
      color: black;
      text-decoration: none;

      @include clickable;
      @include hover-grow(1.2);

      i {
        font-size: 1.3rem;
      }
    }
  }
}

.list-item {
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

.list-item-enter {
  opacity: 0.01;

  &.list-item-enter-active {
    opacity: 1;
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }
}

.list-item-exit {
  opacity: 1;
  &.list-item-exit-active {
    opacity: 0.01;
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft;
  }
} 