.chip {
  display: inline-block;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-image: initial;
  text-decoration: none;
  background: rgb(234, 234, 234);
  margin: 0px 0.625rem 0.625rem 0px;
  padding: 0.3125rem 0.75rem;
  border-radius: 1.125rem;

  &.chip-primary {
    background: get-color('primary', 'base');
  }
}