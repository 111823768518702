.radio-btns {
    display: grid;
    justify-items: center;
    grid-template-rows: [legend] 1fr [buttons] max-content [error] max-content;
    grid-template-columns:repeat(auto-fit, minmax(0, 1fr));
    row-gap: 8x;
    @include v-space(margin-bottom, 0.75);
    @include v-space(margin-top, 0.75);

    legend {
        grid-row: legend;
        text-align: center;

        h3 {
            margin-bottom: 8px;
        }

        p {
            margin-top: 0;
        }
    }

    input {
        grid-row: buttons;
    }

    label {
        padding: 12px;
        color: get-color("greyscale", "black");
        width: 150px;
        height: 100%;
        display: flex;
        border: 2px solid get-color("greyscale", "black");
        border-radius: $button-radius;
        margin: 0 8px;
        justify-content: center;
        cursor: pointer;
    }

    input {
        position: absolute;
        opacity: 0;
    }

    input[type="radio"]:checked+label {
        background-color: get-color("primary");
    }

    .form__error-message {
        grid-row: error;
        padding: 16px 0 8px ;
        text-align: center;
        grid-column: span 2;
    }
}