//// Spacing  ////////
$vertical-space: (
  small: rem-calc(30px),
  medium: rem-calc(30px),
  large: rem-calc(30px)
);

//// Buttons  ////////
$button-radius: rem-calc(3);

$button-padding: (
  xsmall: 3px 10px,
  small: 5px 15px,
  default: 10px 20px,
  large: 20px 30px,
  xlarge: 20px 40px
);

$button-margin: (
  xsmall: 5px auto,
  small: 10px auto,
  default: 15px auto,
  large: 15px auto,
  xlarge: 15px auto
);

$button-sizes: (
  xsmall: rem-calc(12),
  small: rem-calc(14),
  default: rem-calc(16),
  large: rem-calc(20),
  xlarge: rem-calc(24),
);

//// Forms  ////////
$inputPadding: 10px;

//// Transitions  ////////
$transitionTime:  250ms;
$transitionEase:  ease;


//// Icons  ////////
$icon-spacing: 0 rem-calc(5);
$icon-size: rem-calc(20);
$icon-size-small: 70%
