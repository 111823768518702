.text_laed+.checklist {
  @include v-space(margin-top, 2);
}
.checklist {
  text-align: center;
  img {
    margin: 0 auto;
    max-width: rem-calc(80);
  }
  h3 {
    @include v-space(margin-bottom, 0.25);
    @include breakpoint(medium) {
      @include v-space(margin-bottom, 0.5);
      min-height: 48px;
    }
    +.button {
      @include v-space(margin-top, 1);
    }
  }
  h2 {
    @extend h3;
  }
  p {
    @extend .text__small;
    margin-top: 0;
  }
}

.lending-notice {
  @include v-space(margin-top, 1);
  text-align: center;
  h3 {
    min-height: 0;
  }
  p,
  ul,
  strong,
  em {
    font-size: rem-calc(13);
    line-height: rem-calc(15);
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
  }
  p {margin-bottom: rem-calc(7);}
  ul {
    text-align: left;
    margin-top: rem-calc(20);
    margin-bottom: rem-calc(20);
  }
  li {margin-bottom: rem-calc(5);}
  i {
    display: inline-block;
    margin-right: rem-calc(10);
    width: rem-calc(40);
  }
  img {
    display: inline-block;
    max-width: rem-calc(28);
    vertical-align: top;
  }
  .left {text-align: left;}
}
