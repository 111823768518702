/// Create a responsive G unit (gutter unit) declaration given a CSS property
@mixin g-unit($prop, $val, $ignore...) {
  @each $breakpoint, $gutter in $grid-column-gutter {
    @if not index($ignore, $breakpoint) {
      @include breakpoint($breakpoint) {
        #{$prop}: $val * $gutter;
      }
    }
  }
}

@mixin space-unit($prop, $val, $ignore...) {
  @each $breakpoint, $gutter in $spacing-gutter {
    @if not index($ignore, $breakpoint) {
      @include breakpoint($breakpoint) {
        #{$prop}: $val * $gutter;
      }
    }
  }
}

/// Create a responsive vertical space for between elements
@mixin v-space($prop, $val, $ignore...) {
  @each $breakpoint, $space in $vertical-space {
    @if not index($ignore, $breakpoint) {
      @include breakpoint($breakpoint) {
        #{$prop}: $val * $space;
      }
    }
  }
}

// Custom typography map for responsive headings and text
@mixin typography-styles($config) {
  // Only set what is passed in
  @each $key in (font-family, font-size, font-weight, letter-spacing, line-height, color) {
    $value: map-get($config, $key);

    @if ( $value != null ) {
      @if ( $key == 'font-size') or ( $key == 'letter-spacing') or ( $key == 'line-height') {
        $value: rem-calc($value);
      }

      #{$key}: $value;
    }
  }
}

// Generates the responsive typography styles for each breakpoint defined in the
// variable config.
@each $type, $levels in $typography {
  @each $level, $config in $levels {
    $selector: $level; // Determine if this style needs a base selector in addition to a class
    $no-tag: map-has-key($config, no-tag) and map-get($config, no-tag) == true;
    $no-default: not map-has-key($config, default-selector); // Change the `$selector` so it reflects the default if one is set
    @if not $no-default {
      $selector: unquote(map-get($config, default-selector));
    }
    @if $no-tag and $no-default {
      .#{$level} {
        @include typography-styles($config);
      }
    } @else {
      #{$selector},
      .#{$level} {
        @include typography-styles($config);
      }
    } // Loop through each breakpoint and set the styles
    @each $breakpoint, $size in $breakpoints {
      @if map-has-key($config, $breakpoint) {
        $break-config: map-get($config, $breakpoint);
        @include breakpoint($breakpoint) {
          @if $no-tag and $no-default {
            .#{$level} {
              @include typography-styles($break-config);
            }
          } @else {
            #{$selector},
            .#{$level} {
              @include typography-styles($break-config);
            }
          }
        }
      }
    }
  }
}

// font face
// builds 'font-face' inlcude for required font family
// have removed eot ttf svg from $exts. e.g. $exts: eot woff2 woff ttf svg
@mixin font-face($name, $path, $weight: null, $style: null, $exts: woff2 woff) {
  $src: null;
  $extmods: ( eot: '?', svg: '#' + str-replace($name, ' ', '_'));
  $formats: ( otf: 'opentype', ttf: 'truetype');
  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + '.' + $extmod)) format(quote($format)), comma);
  }
  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

// Absolute centre items
@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin object-absolute-centre($width, $height, $ignore...) {
  @include absolute-center;
  height: $height;
  width: $width;
  z-index: 1;
}

//make border
// create border with radius
@mixin border($width: rem-calc(1px), $style: solid, $color: get-color('greyscale', 'grey6'), $radius: 0) {
  border-width: $width;
  border-style: $style;
  border-color: $color;
  border-radius: $radius;
}

//Transitions
// transition element changes
@mixin transition ($prop: all) {
  transition: $prop $transitionTime $transitionEase;
}

//Lists
//resets all values on a list
@mixin list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

//remove bottom margin on last value e.g. <p>, <ul>
@mixin last-margin($val) {
  #{$val}:last-of-type {
    margin-bottom: 0;
  }
}

//a tag with no underline
@mixin anchor-plain {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

// tests for dev
@mixin test {
  background: #96f4a8;
  border: 1px solid #0bf737;
}

@mixin guide-h {
  border-bottom: 1px solid #0bf737;
  border-top: 1px solid #0bf737;
}

@mixin guide-y {
  border-left: 1px solid #0bf737;
  border-right: 1px solid #0bf737;
}

@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

// focus

@mixin focus {
  @include transition;
  border-color: get-color('forms', 'focus');
  box-shadow: 0 0 1px 2px lighten(get-color('forms', 'focus'), 40%);
  outline: none;
}

@mixin form-error {
  border-color: get-color('forms', 'error');
}

@mixin focus-error {
  @include focus;
  @include form-error;
  box-shadow: 0 0 1px 2px lighten(get-color('forms', 'error'), 30%);
}

@mixin hover-grow($scale) {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);

  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;

  &:hover, &:focus {
    -webkit-transform: scale($scale);
    transform: scale($scale);
  }
}

@mixin clickable {
  &:hover, &:focus {
    cursor: pointer;
  }
}

@mixin d-flex {
  display: -webkit-box!important;
  display: -ms-flexbox!important;
  display: flex !important;
}

@mixin flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

@mixin justify-content-center {
  -webkit-box-pack: center!important;
  -ms-flex-pack: center!important;
  justify-content: center!important;
}

@mixin justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: end !important;
}

@mixin justify-content-between {
  -webkit-box-pack: space-between !important;
  -ms-flex-pack: space-between !important;
  justify-content: space-between !important;
}

@mixin align-items-center {
  -webkit-box-align: center!important;
  -ms-flex-align: center!important;
  align-items: center!important;
}

@mixin align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}