//set the outside gutters
// adds padding to the row
.row {
  padding: 0 get-grid-padding(map-get($grid-outside-gutter, small), map-get($grid-column-gutter, small));
  @include breakpoint(medium) {
    padding: 0 get-grid-padding(map-get($grid-outside-gutter, medium), map-get($grid-column-gutter, medium));
  }
  @include breakpoint(large) {
    padding: 0 get-grid-padding(map-get($grid-outside-gutter, large), map-get($grid-column-gutter, large));
  }
  .row {
    @include breakpoint(medium) {
      padding: 0;
    }
  }
}
