.DefaultProgressBar__vertical {
  width: 24px;
  height: 100%
}
.DefaultProgressBar_progressBar {
  background-color: get-color('primary', 'base');
  position: absolute
}
.DefaultProgressBar_progressBar__vertical {
  height: 100%;
  width: 24px
}
.DefaultProgressBar_background__vertical {
  height: 100%;
  top: 0px;
  width: 15px
}
.DefaultProgressBar_background__horizontal {
  left: -2px !important;
  height: 6px;
  top: 2px
}
.DefaultHandle_handle {
  width: 24px;
  height: 24px;
  border-width: 3px;
  border-style: solid;
  border-color: get-color('primary', 'dark');
  background-color: get-color('greyscale', 'white');
  border-radius: 50%;
  outline: none;
  z-index: 2;
  box-shadow: 0 2px 2px #dbdbdb;

  @include hover-grow(1.2);
  @include clickable;
}
.DefaultHandle_handle:focus {
  box-shadow: #abc4e8 0 0 1px 1px
}
.DefaultHandle_handle:after {
  content: "";
  display: block;
  position: absolute;
  background-color: #dadfe8
}
.DefaultHandle_handle:before {
  content: "";
  display: block;
  position: absolute;
  background-color: #dadfe8
}
.DefaultHandle_handle__horizontal {
  margin-left: -12px;
  top: -5px
}
.DefaultHandle_handle__vertical {
  margin-top: -12px;
  left: -10px
}
.DefaultHandle_handle__disabled {
  border-color: #dbdbdb
}
.DefaultBackground {
  background-color: get-color('greyscale', 'grey3');
  height: 15px;
  width: 100%;
  position: relative
}
.DefaultBackground_background__horizontal {
  height: 6px;
  top: 2px;
  left: -2px;
  bottom: 4px;
  width: 100%
}
.DefaultBackground_background__vertical {
  width: 15px;
  top: 0px;
  height: 100%
}
.rheostat {
  position: relative;
  overflow: visible
}
@media (min-width: 1128px) {
  .autoAdjustVerticalPosition {
    top: 12px
  }
}
.rheostat__vertical {
  height: 100%
}
.handleContainer {
  height: 15px;
  top: -2px;
  left: -2px;
  bottom: 4px;
  width: 100%;
  position: absolute;

  &:hover {
    cursor: pointer;
  }
}
.rheostat_background {
  background-color: #fcfcfc;
  border: 1px solid #d8d8d8;
  position: relative
}
.rheostat_background__horizontal {
  height: 15px;
  top: -2px;
  left: -2px;
  bottom: 4px;
  width: 100%
}
.rheostat_background__vertical {
  width: 15px;
  top: 0px;
  height: 100%
} 


.slider-container {
  flex-wrap: wrap;

  .slider-details {
    display: flex;
    justify-content: space-between;

    .slider-min, .slider-max {
      margin-top: 10px;
    }
  }
}

.split-slider {
  .DefaultBackground {
    background-color: get-color('primary', 'dark');
  }
}