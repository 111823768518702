[hidden] {
  display: none !important;
}

.react-fine-uploader-gallery-nodrop-container,
.react-fine-uploader-gallery-dropzone {
  border-radius: 6px;
  background-color: #FAFAFA;
  max-height: 490px;
  min-height: 310px;
  overflow-y: hidden;
  padding: 15px 15px 15px 5px;
  position: relative;
}

.react-fine-uploader-gallery-dropzone {
  border: 2px dashed #00ABC7;
}
.react-fine-uploader-gallery-dropzone-upload-icon {
  height: 36px;
  margin-bottom: -6px;
  margin-right: 10px;
  width: 36px;
}

.react-fine-uploader-gallery-nodrop-container {
  border: 2px solid get-color('greyscale', 'grey5');
}

.react-fine-uploader-gallery-dropzone-active  {
  background: #FDFDFD;
  border: 2px solid #00ABC7;
}

.react-fine-uploader-gallery-dropzone-content,
.react-fine-uploader-gallery-nodrop-content {
  font-size: 36px;
  left: 0;
  opacity: 0.25;
  position: absolute;
  text-align: center;
  top: 38%;
  width: 100%;
}

.react-fine-uploader-gallery-file-input-container {
  background: get-color('primary', 'base');
  border: 1px solid get-color('primary', 'light');
  border-radius: 3px;
  color: get-color('greyscale', 'grey1');
  display: inline;
  float: left;
  margin-left: 10px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  text-align: center;
  width: 150px;
}
.react-fine-uploader-gallery-file-input-container:hover {
  background: get-color('primary', 'light');
}
.react-fine-uploader-gallery-file-input-container:focus {
  outline: 1px dotted get-color('greyscale', 'black');
}
.react-fine-uploader-gallery-file-input-content {
  display: inline-block;
  margin-top: -2px;
}
.react-fine-uploader-gallery-file-input-upload-icon {
  fill: get-color('greyscale', 'grey1');
  height: 24px;
  margin-bottom: -6px;
  margin-right: 5px;
  width: 24px;
}

.react-fine-uploader-gallery-progress-bar,
.react-fine-uploader-gallery-total-progress-bar {
  border-radius: 3px;
  background: get-color('primary', 'base');
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) inset;
  height: inherit;
}
.react-fine-uploader-gallery-progress-bar-container,
.react-fine-uploader-gallery-total-progress-bar-container {
  background: #F2F2F2;
  border-radius: 3px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) inset;
  position: absolute;
}
.react-fine-uploader-gallery-total-progress-bar-container {
  display: inline-block;
  height: 0;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 4px;
  width: 70%;
}
.react-fine-uploader-gallery-progress-bar-container {
  height: 15px;
  left: 50%;
  opacity: 0.9;
  top: 60px;
  transform: translateX(-50%);
  width: 90%;
  z-index: 1;
}
.react-fine-uploader-gallery-files {
  clear: both;
  list-style: none;
  max-height: 450px;
  overflow-y: auto;
  padding-left: 1em;
  padding-top: 20px;
}
.react-fine-uploader-gallery-files-enter {
  opacity: 0.01;

  .react-fine-uploader-gallery-files-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
}

.react-fine-uploader-gallery-files-leave {
  opacity: 1;

  .react-fine-uploader-gallery-files-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
}

.react-fine-uploader-gallery-file {
  background-color: get-color('greyscale', 'white');
  border-radius: 9px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.22);
  display: inline-block;
  font-size: 13px;
  height: 165px;
  line-height: 16px;
  margin: 0 25px 25px 10px;
  position: relative;
  vertical-align: top;
  width: 130px;
}

.react-fine-uploader-gallery-thumbnail-container {
  display: block;
  height: 130px;
  text-align: center;
}
.react-fine-uploader-gallery-thumbnail {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.react-fine-uploader-gallery-thumbnail-icon-backdrop,
.react-fine-uploader-gallery-upload-failed-icon,
.react-fine-uploader-gallery-upload-success-icon {
  left: 50%;
  opacity: 0.5;
  position: absolute;
  top: 39%;
  transform: translate(-50%, -50%);
}
.react-fine-uploader-gallery-upload-failed-icon,
.react-fine-uploader-gallery-upload-success-icon {
  height: 60px;
  width: 60px;
  z-index: 1;
}
.react-fine-uploader-gallery-upload-success-icon {
  fill: #008000;
}
.react-fine-uploader-gallery-upload-failed-icon {
  fill: #f00;
}
.react-fine-uploader-gallery-thumbnail-icon-backdrop {
  background-color: get-color('greyscale', 'white');
  border-radius: 30px;
  height: 50px;
  width: 50px;
}

.react-fine-uploader-gallery-file-footer {
  padding-left: 5px;
  padding-right: 5px;
}

.react-fine-uploader-gallery-filename {
  display: block;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.react-fine-uploader-gallery-filesize {
  display: block;
  float: right;
}

.react-fine-uploader-gallery-status {
  font-style: italic;
}

.react-fine-uploader-gallery-cancel-button,
.react-fine-uploader-gallery-delete-button,
.react-fine-uploader-gallery-pause-resume-button,
.react-fine-uploader-gallery-retry-button {
  background: transparent;
  border: 0;
  position: absolute;

  &:hover {
    svg {
      fill: #808080;
    }
  }
  &:focus {
    outline: none;
    
    svg {
      fill: #808080;
    }    
  }  
}
.react-fine-uploader-gallery-cancel-button,
.react-fine-uploader-gallery-delete-button {
  right: -18px;
  top: -12px;
}
.react-fine-uploader-gallery-pause-resume-button,
.react-fine-uploader-gallery-retry-button {
  left: -18px;
  top: -12px;
}
