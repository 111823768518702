//loan selection
//selection list within expand area
.loanSelect {
  &__other {
    margin: 0 rem-calc(5) 0 rem-calc(47);
    position: relative;

    button {
      background-color: get-color('greyscale', 'white');
      color: #747678;
      bottom: 1px;
      font-size: rem-calc(12);
      line-height: rem-calc(20);
      padding: rem-calc(10);
      position: absolute;
      right: 1px;
      text-transform: uppercase;
      z-index: 1;

      i {
        font-size: rem-calc(20);
        margin-right: rem-calc(5);
        position: relative;
        float: left;
      }
      
      i::before {
        position: relative;
        margin-top: -40px;
      }

      &:hover {
        color: get-color('greyscale', 'black');
        cursor: pointer;
      }
    }
  }

  .card {
    @include v-space(margin-bottom, 0.5);
    text-align: center;
  }

  .detail {
    padding-bottom: rem-calc(12);
  }
}

//loan summary
.loan-summary {
  @extend .summary-box;
  @include v-space (margin-bottom, 0.75);
  background-color: get-color('greyscale', 'grey1');
  color: get-color('greyscale', 'white');

  .value {
    font-weight: 700;
  }

  ul {
    @include clearfix;
  }

  li {
    @include v-space(margin-bottom, 0.1);
  }

  + .text__small {
    @include v-space (margin-bottom, 1.5);
  }
}
