//--- grids
// display rows and cells
.grid,
.forms-l {

  .row,
  .grid-x {
    background: #d6ecfa;
    margin-bottom: 5px;

    .columns,
    .cell {
      background: #7bc1ef;
      &:nth-child(even) {
        background: #1779ba;
      }
    }
    
    .highlight {
      display: block;
    }
  }
}

//--- Buttons
.buttons {
  .button {
    display: block;
    margin-bottom: 5px;
  }
}

//--- icons
.icons {
  i {
    font-size: 100px;
  }
}
