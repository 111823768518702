// == tracker progress module
//
// tracker states
// 0 - your progress
// 2 - application assessment
// 3 - sign loan contract
// 4 - money will be assessment
// 5 - complete / money assessment
// x - withdrawn / declined
.progress-tracker {
  $dl-horizontal-offset: 120px;
  $green: get-color('forms', 'success');
  $red: get-color('forms', 'error');
  @include list-reset;
  padding: 0 20px;
  h2 {
    margin-bottom: rem-calc(40);
  }
  &--withdrawn,
  &--declined,
  &--lapsed {
    .progress-tracker__item--complete {
      &::before,
      &::after {
        background-color: get-color('greyscale', 'grey3');
      }
    }
  }
  &__item {
    font-size: rem-calc(13);
    line-height: rem-calc(24);
    position: relative;
    padding: 0 0 30px 55px;
    margin: 0;
    @include breakpoint(medium) {
      padding-left: calc(50% + 55px);
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
    } // Icon/Number
    &::before {
      content: attr(data-index);
      color: $black;
      font-weight: 600;
      left: 0;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: get-color('greyscale', 'grey6');
      border-radius: 50%;
      z-index: 5;
      @include breakpoint(medium) {
        left: 50%;
        transform: translateX(-50%);
      }
    } // Rule/Line
    &::after {
      left: 14px; // half the circle width -1
      bottom: 0;
      width: 2px;
      background-color: $black;
      @include breakpoint(medium) {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &__content {
      display: none;
      margin-top: rem-calc(5);
      .label {
        margin-right: rem-calc(5);
      }
    }
    &--complete {
      //@include icon(checkbox-on); TODO FIX
      padding-bottom: 30px; // Reset any extra padding
      &::before,
      &::after {
        background-color: $green;
        color: get-color('greyscale', 'white');
        font-size: rem-calc(22);
        font-weight: 400;
      }
      &:last-of-type {
        &::after {
          display: none;
        }
      } // Title
      strong {
        @include breakpoint(medium) {
          position: absolute;
          top: 0;
          right: calc(50% + 50px);
        }
      } // No CTA once complete
      .button-wrapper {
        display: none;
      } //desktop complete padding
      @include breakpoint(medium) {
        padding-bottom: 70px;
      }
    }
    &--current {
      &::before {
        background-color: $black;
        color: $white;
      }
      &::after {
        background-color: get-color('greyscale', 'grey6');
        display: none;
        @include breakpoint(medium) {
          opacity: 0;
        }
      }
      @include breakpoint(medium) {
        padding-left: 0;
        text-align: center;
        padding-top: 50px;
      }
    } // Once the title is position absolute,
    // there is a gap above stacked complete items
    // This is the fix for now... Will investigate further
    // at a later date
    &--complete+&--complete {
      @include breakpoint(medium) {
        margin-top: -24px;
      }
    }
    &--complete &__content {
      display: block;
    }
    &--current &__content {
      display: block;
    }
    &--todo {
      padding-bottom: 30px; // Reset any extra padding
      &::after {
        background-color: get-color('greyscale', 'grey6');
        transform: translateY(-50%);
      }
    }
    &--error {
      //@include icon(remove); TODO FIX
      padding-bottom: 30px; // Reset any extra padding
      &::before,
      &::after {
        background-color: $red;
        color: get-color('greyscale', 'white');
        font-size: rem-calc(22);
      }
      &::after {
        display: none;
      }
    }
    &--has-cta {
      padding-bottom: 101px; // 30px default + btn height + 20px for spacing
    }
    .button-wrapper {
      background-color: #f2f5f7;
      position: absolute;
      bottom: 5px;
      left: -20px;
      right: -20px;
      z-index: 10;
      padding: 5px;
      @include breakpoint(medium) {
        background-color: #fff;
        .button {
          max-width: 400px;
        }
      }
    }
  }
  .declined-reasons {
    text-align: left;
    margin-top: rem-calc(20);
    margin-bottom: rem-calc(20);
    list-style: disc;

    li {
      margin-bottom: rem-calc(5);
    }
  }
  .currentStepStatus {
    max-width: 270px;
    margin: 0 auto;
    line-height: 2em;
    @include breakpoint(medium) {
      padding: 5px;
      display: block;
    }
  }
}

.tracker__errors {
  @extend .lending-notice;

  ul {
    @include breakpoint(medium) {
      max-width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
