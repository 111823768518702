

[hidden] {
  display: none !important;
}

.react-fine-uploader-total-progress-bar-container {
  height: 1em;
  background-color: get-color('greyscale', 'grey2');
}

.react-fine-uploader-total-progress-bar {
  height: 1em;
  background-color: get-color('primary', 'base');
}

.error {
  color: get-color('forms', 'error');
}
