.error-wrapper {

  label {
    color: get-color('greyscale', 'grey2');
    display: block;
    font-size: rem-calc(14);
    font-weight: 400;
    width: 100%;
  }

  &.show-error {
    .error-wrapper-inner {
      border: solid 1.5px get-color('forms', 'error');
    }  
  }

  &.no-border {
    .error-wrapper-inner {
      padding: 0;
      border: none !important;
    }  
  }

  .error-wrapper-inner {
    @include g-unit(padding, 0.5);

    .form__error-message {
      text-align: left;
    }
  }
}
