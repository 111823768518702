// personal loans Header
.header {
  background: get-color('greyscale', 'white');
  border-bottom: 1px solid #d6d6d6;
  padding: rem-calc(17) rem-calc(7);
  position: relative;

  @include breakpoint(medium) {
    padding: rem-calc(20) 0;
  }

  .logo {
    width: 150px;
    @include breakpoint(medium) {
      width: 203px;
    }
  }

  .loan-summary {
    @include clearfix;
    background-color: transparent;
    color: get-color('system');
    line-height: 1;
    margin: 0;
    margin-top: rem-calc(17);
    padding: 0;
    position: relative;

    @include breakpoint(medium) {
      font-size: 20px;
      float: right;
      margin-top: rem-calc(8);
    }

    .loan-label {
      font-family: $openSans;
      float: left;

      @include breakpoint(medium) {
        @include g-unit(margin-right, 1);
      }
    }

    .loan-amount {
      font-family: $baloo-paaji-bold;
      float: right;

      i {
        font-size: $icon-size-small;
        margin: $icon-spacing;
      }
    }

    &__overview {
      @include list-reset;
      background-color: get-color('greyscale', 'white');
      border: 1px solid get-color('greyscale', 'grey6');
      border-radius: rem-calc(4);
      box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.14);
      font-family: $openSans;
      font-size: rem-calc(14);
      padding: rem-calc(15) rem-calc(25);
      position: absolute;
      right: 0;
      top: calc(100% + 10px);
      width: 100%;
      z-index: 99;

      @include breakpoint(medium) {
        width: 330px;
      }

      &::after,
      &::before {
        bottom: 100%;
        left: 85%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &::after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: get-color('greyscale', 'white');
        border-width: 8px;
        margin-left: -8px;
      }

      &::before {
        border-color: rgba(229, 229, 229, 0);
        border-bottom-color: get-color('greyscale', 'grey6');
        border-width: 9px;
        margin-left: -9px;
      }

      li {
        @include clearfix;
        float: none;
        padding: rem-calc(5) 0;
        margin: 0;
        width: 100%;

        &:nth-child(2n) {
          float: none;
        }
      }

      .label {
        float: left;
      }
      
      .value {
        float: right;
        font-family: $openSans;
        text-align: right;
      }
    } // drop down box details
  }
}

.loan-preview {
  text-align: right;
  font-family: $openSans;
  position: relative;
  display: none;
  @include breakpoint(medium) {
    display: block;
  }
  &__label {
    font-size: rem-calc(18);
    padding: rem-calc(15);
    line-height: 2;
    cursor: pointer;
  }
  &__toggle {
    font-family: $baloo-paaji-bold;
    margin-left: rem-calc(15);
    cursor: pointer;
    &:focus {
      outline: none;
    }
    i {
      font-size: rem-calc(12);
      margin-left: rem-calc(5);
    }
  }
  &__overlay-container {
    transform: translateY(rem-calc(-15));
    transition: 0.5s opacity cubic-bezier(0.19, 1, 0.22, 1), 0.5s transform cubic-bezier(0.19, 1, 0.22, 1);
    max-width: rem-calc(400);
    width: 100%;
  }
  &__overlay {
    background: get-color('greyscale', 'white');
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.16);
    padding: rem-calc(15);
    list-style: none;
    text-align: left;
    z-index: 100;
    border: 1px solid get-color('greyscale', 'grey7');
    border-radius: rem-calc(4);
    transform: translateY(rem-calc(-15));
    transition: 0.5s opacity cubic-bezier(0.19, 1, 0.22, 1), 0.5s transform cubic-bezier(0.19, 1, 0.22, 1);
    > li {
      margin: rem-calc(5) 0;
      .label,
      .value {
        display: inline-block;
      }
      .label {
        width: 75%;
      }
      .value {
        font-family: $baloo-paaji-bold;
        text-align: right;
        width: 25%;
      }
    }
  }
}

.loan-preview-item {
  display: flex;
  .loan-preview-item-icon {
    margin-right: 25px;
    vertical-align: middle;
    width: 20px;
  }
  .loan-preview-item-text {
    text-align: center;
    width: 100%;
  }
}