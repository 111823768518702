.__react_component_tooltip {
  background-color: get-color('primary') !important;
  color: black !important;

  &.show {
    opacity: 1 !important;
  }
  &:after {
    border-top-color:get-color('primary') !important;
  }
}