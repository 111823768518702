body {
  background-color: get-color('greyscale', 'grey7');
}

.canvas__wrapper {
  transition: 0.5s opacity;
  animation: page-animation 1200ms cubic-bezier(0.19, 1, 0.22, 1);
}

@keyframes page-animation {
  0% {
    opacity: 0;
    transform: translateY(30px);
  } 
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.loading {
  .loader-wrapper {
    opacity: 1;
    .loader {
      .text {
        transition-delay: 5s;
        opacity: 1;
      }
    }
  }
  .canvas__wrapper {
    opacity: 0.15;
    pointer-events: none;
  }
}

.loader-wrapper {
  position: fixed;
  top: calc(50% - 30px);
  left: 0;
  right: 0;
  margin: auto auto 0;
  z-index: 500;
  opacity: 0;
  transition: 0.5s;
  pointer-events: none;
  text-align: center;
  .loader {
    display: block;
    position: relative;
    max-width: 50%;
    margin: 0 auto;
    img {
      width: 60px;
      animation: loading-spinner 1s linear;
      animation-iteration-count: infinite;
    }
    .text {
      font-size: 28px;
      font-family: $baloo-paaji-bold;
      opacity: 0;
      transition: 0.5s opacity 0s;
      white-space: nowrap;
      margin: 8px 0 0;
    }
  }
}

.loading-spinner {
  .loading-spinnner-image-container {
    text-align: center;
    img {
      animation: loading-spinner 1s linear;
      animation-iteration-count: infinite;
  
    }
  }
  
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
