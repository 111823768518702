.accordion {
  $ease: cubic-bezier(0.19, 1, 0.22, 1);
  background-color: get-color('greyscale', 'white');
  border: 1px solid get-color('greyscale', 'black');
  border-radius: rem-calc(3);
  color: get-color('greyscale', 'black');

  &__item + &__item {
    border-top: 1px solid get-color('greyscale', 'black');
  }
  &__item {
    &--open {
      .accordion__item__content {
        visibility: visible;
        max-height: 1000px;
        opacity: 1;
        transition: 0.5s opacity $ease, 0.3s max-height $ease, 0s visibility;
      }
      .accordion__item__arrow {
        transform: scale(1, -1);
      }
    }
    &--closed {
      .accordion__item__content {
        visibility: hidden;
        max-height: 0;
        opacity: 0;
        transition: 0.5s opacity $ease, 0.3s max-height $ease, 0s visibility 0.5s;
      }
    }
    &__title-wrapper {
      width: 100%;
      display: flex;
      @include transition;
      color: inherit;
      padding: rem-calc(16);
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: get-color('greyscale', 'grey7');
      }
      &:focus-visible {
        outline: 2px solid black;
      }
    }
    &__title {
      flex: 1;
      font-size: rem-calc(18);
      text-align: left;
    }
    &__icon {
      width: rem-calc(32);
      margin-right: rem-calc(20);
    }
    &__arrow {
      @include transition;
      font-size: rem-calc(10);
      display: inline-block;
    }
    &__content {
      overflow: hidden;
    }
    &__content-wrapper {
      padding: rem-calc(10) rem-calc(20);
    }
  }
}
