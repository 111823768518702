
.swipe-container {
  width: 80%;
  max-width: 80%;
  overflow: auto;
  display: flex;
  padding: 12px;
  position: relative;
  transition: all 100ms linear;
  scroll-snap-type: x mandatory;
  margin: 0 auto;
  scroll-behavior: smooth;

  &__child {
    text-align: center;
    min-width: 75%;
    width: 75%;
    height: 100%;
    justify-content: center;
    display: flex;
    scroll-snap-align: center;

    label {
      padding: 12px;
      color: get-color("greyscale", "black");
      width: 150px;
      height: 100%;
      display: flex;
      border: 2px solid get-color("greyscale", "black");
      border-radius: $button-radius;
      margin: 0 8px;
      justify-content: center;
      cursor: pointer;
    }
    input {
      position: absolute;
      opacity: 0;
    }

    input[type="radio"]:checked + label {
      background-color: get-color("primary");
    }
  }

  

  @include breakpoint(large) {
    overflow: hidden;

    &__child {
      min-width: 0;
      width: auto;
    }

   
  }
}

.fade-container {
  position: relative;
  width: 100%;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 25px;
    background: linear-gradient(
      90deg,
      get-color("greyscale", "grey7") 0%,
      rgba(get-color("greyscale", "grey7"), 0) 100%
    );
  }

  &::before {
    left: 10%;
    z-index: 1;
  }

  &::after {
    right: 10%;
    transform: scale(-1);
  }

  @include breakpoint(large) {
    &::before,
    &::after {
      display: none;
    }
  }
}

.repayment-box {
  border: 1px solid get-color('greyscale', 'black');
  border-radius: $button-radius;
  padding: 10px 20px;

}