$footer-height: 256px;
$footer-padding: 30px;

html {
  overflow-y: scroll;
}

html,
body,
.root,
.app {
  height: 100%;
  min-height: 100%;
}

.content {
  min-height: 100%;

  @include breakpoint(medium) {
    min-height: calc(100% - #{$footer-height});
  }
}

.footer {
  background-color: get-color('greyscale', 'grey1');
  color: get-color('greyscale', 'white');
  padding-top: $footer-padding;
  text-align: center;
  height: auto;
  max-height: auto;

  @include breakpoint(medium) {
    overflow-y: hidden;
    height: $footer-height;
    max-height: $footer-height;
  }

  &__left {
    @include breakpoint(medium) {
      text-align: left;
    }
  }

  &__right {
    @include breakpoint(medium) {
      text-align: right;
    }
  }

  &__brand {
    height: 32px;
    margin-bottom: 0;
    @include breakpoint(medium) {
      margin-top: 16px;
      margin-bottom: 27px;
    }
  }

  &__phone {
    font-family: $baloo-paaji;
    font-size: rem-calc(16);
    margin-top: $footer-padding * 0.5;
    margin-bottom: $footer-padding * 0.5;
  }

  &__legal {
    @include list-reset;
    font-size: rem-calc(12);
    margin-top: $footer-padding * 0.5;
    margin-bottom: $footer-padding * 0.5;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        @include breakpoint(medium) {
          display: inline-block;
          margin-right: $footer-padding * 0.3;
          margin-bottom: 0;
          &:last-of-type {
            margin: 0;
          }
        }
        a {
          @include anchor-plain;
          color: get-color('greyscale', 'white');
          @include breakpoint(medium) {
            font-size: rem-calc(12);
          }
        }
      }
    }
  }

  &__acl {
    font-size: rem-calc(12);
    margin-top: $footer-padding * 0.25;
    margin-bottom: $footer-padding * 0.5;
  }

  &__security {
    display: inline-block;
    text-align: right;
    right: 0;
    width: 112px;
  }

  &__warning {
    display: block;
    margin-top: $footer-padding * 0.5;
    margin-bottom: $footer-padding * 0.5;
    a {
      display: inline-block;
      font-size: rem-calc(12);
      color: get-color('greyscale', 'white');
      text-decoration: none;
      padding: 8px 16px;
      margin: 0;
      border: 1px solid get-color('greyscale', 'grey2');
      border-radius: 20px;
      &:hover {
        background: get-color('greyscale', 'grey2');
      }
      i {
        text-decoration: none;
        font-size: rem-calc(18);
        line-height: 0;
        vertical-align: middle;
        margin-right: 8px;
        color: get-color('greyscale', 'white');
      }
    }
  }

  &__copyright,
  &__abn,
  &__version {
    display: block;
    font-size: rem-calc(12);
    margin-top: $footer-padding * 0.5;
    margin-bottom: $footer-padding * 0.5;
  }

  &__version {
    margin-bottom: $footer-padding;
  }
}
