/// loan Canvas
/// base layouts of the loans form
.canvas {
  @include v-space(padding-top, 0.5);
  @include v-space(padding-bottom, 0.5);

  @include breakpoint(medium) {
    @include v-space(padding-top, 1);
    @include v-space(padding-bottom, 1);
  }

  &__wrapper {
    max-width: rem-calc(866);
    padding-right: 0;
    padding-left: 0;

    &__content {
      padding: 0;
    }

    @include breakpoint(medium) {
      @include border($radius: rem-calc(10px));
      background: get-color('greyscale', 'white');
      &__content {
        padding: rem-calc(50) 0;
      }
    }

    &-secure {
      @include border($color: get-color('greyscale', 'grey5'), $radius: rem-calc(10));
      @include v-space(padding-bottom, 0.5);
      @include v-space(padding-top, 0.5);
      background-color: get-color('greyscale', 'white');
      padding-left: map-get($grid-outside-gutter, small);
      padding-right: map-get($grid-outside-gutter, small);

      @include breakpoint(medium) {
        border-color: get-color('greyscale', 'grey3');
        background-color: get-color('greyscale', 'grey7');
        padding-left: map-get($grid-outside-gutter, medium);
        padding-right: map-get($grid-outside-gutter, medium);
      }
      
      @include breakpoint(large) {
        padding-left: map-get($grid-outside-gutter, large);
        padding-right: map-get($grid-outside-gutter, large);
      }
    }
  }
}
