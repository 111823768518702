.loan-panel {
  @include g-unit(padding, 1);
  @include v-space(margin-top, 0.5);
  @include v-space(margin-bottom, 0.5);
  background-color: get-color('greyscale', 'white');
  border: 1px solid get-color('forms', 'outline');
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  position: relative;
  &::after {
    @include horizontal-center;
    content: '';
    background-color: get-color('greyscale', 'white');
    width: 10px;
    height: 10px;
    top: -4px;
    z-index: 0;
  }
  @include breakpoint(medium) {
    @include g-unit(padding, 0.5);
  }
  &__title,
  &__amount {
    @include g-unit(padding, 0.25);
  }
  &__title {
    color: get-color('greyscale', 'black');
    font-size: rem-calc(18);
    font-weight: 600;
    display: block;
    flex-grow: 2;
  }
  &__amount {
    flex-grow: 1;
  }
  &__value,
  &__frequency {
    text-align: right;
    font-size: rem-calc(14);
  }
  &__value {
    font-weight: 700;
    font-size: rem-calc(26);
  }

  .loan-icon {
    @include horizontal-center;
    color: get-color('forms', 'success');
    background-color: transparent;
    top: -9px;
    z-index: 1;
    font-size: 20px;
    line-height: 0;
  }
}
