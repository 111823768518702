#bank-login-form {
  #login-panel-header {
    display: grid;
    grid-template-columns: auto auto 6em;
  
    .text-left {
      grid-column-start: 1;
      grid-column-end: 2;
    }
  
    .login-heading-security {
      grid-column-start: 3;
      grid-column-end: 4;
    }
  } 
}