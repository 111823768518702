//utilities helpers

// centre align an element
.float-center {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

.float-left {
  float: none !important;
}
//hide an element
.hidden {
  @include element-invisible;
}

//Display Helpers
.d-flex {
  @include d-flex;
}


.d-none {
  display: none;
}

//Flex Helpers
.justify-content-center {
  @include justify-content-center;
}

.align-items-center {
  @include align-items-center;
}

//Position Helpers
.p-relative {
  position: relative!important;
}

//Width, height, margin flex helpers
.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.w-half {
  width: 50%;
}

.w-full {
  width: 100%;
}

//messy fix for text bleeding. should still keep padding in most cases to not bleed completel over
.-mx-6 {
  margin-left: -6px;
  margin-right: -6px;
}

@for $i from 0 through 100 {
  .flex-#{$i} {
      flex: #{$i} !important;
  }

  .m-#{$i} {
    margin: #{$i}px !important;
  }

  .mt-#{$i} {
    margin-top: #{$i}px !important;
  }

  .mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }

  .ml-#{$i} {
    margin-left: #{$i}px !important;
  }

  .mr-#{$i} {
    margin-right: #{$i}px !important;
  }

  .p-#{$i} {
    padding: #{$i}px !important;
  }

  .pt-#{$i} {
    padding-top: #{$i}px !important;
  }

  .pb-#{$i} {
    padding-bottom: #{$i}px !important;
  }

  .pl-#{$i} {
    padding-left: #{$i}px !important;
  }

  .pr-#{$i} {
    padding-right: #{$i}px !important;
  }
  .h-#{$i} {
    height: #{$i}px !important;
  }
  .w-#{$i} {
    width: #{$i}px !important;
  }
}