//model styling
.modal {
  cursor: auto;
  max-width: 35em;
  @include border($radius: 5px);
  padding: rem-calc(40) rem-clac(20);
  @include breakpoint(medium) {
    padding: rem-calc(35) rem-calc(70);
  }
  
  h1 {
    margin-top: 2rem;
  }
  
  //close button
  >a {
    cursor: pointer;
    display: block;
    color: get-color('greyscale', 'black');
    left: auto;
    right: 0;
    top: rem-calc(10);
    &::before {
      font-size: rem-calc(20);
    }
  }

  
}
