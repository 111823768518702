.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease-in;
  opacity: 1;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

@keyframes pulse-me {
  0% {
    transform: scale(0.5);
    opacity: 0;
	}

  50% {
    opacity: 0.5;
	}

  100% {
    transform: scale(3);
    opacity: 0;
	}
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader2 {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes fadeOutLeft {
  0% {
      opacity: 1
  }

  to {
      opacity: 0;
      -webkit-transform: translate3d(-100%,0,0);
      transform: translate3d(-100%,0,0)
  }
}

@keyframes fadeOutLeft {
  0% {
      opacity: 1
  }

  to {
      opacity: 0;
      -webkit-transform: translate3d(-100%,0,0);
      transform: translate3d(-100%,0,0)
  }
}

@-webkit-keyframes fadeInRight {
  0% {
      opacity: 0;
      -webkit-transform: translate3d(100%,0,0);
      transform: translate3d(100%,0,0)
  }

  to {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0)
  }
}

@keyframes fadeInRight {
  0% {
      opacity: 0;
      -webkit-transform: translate3d(100%,0,0);
      transform: translate3d(100%,0,0)
  }

  to {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0)
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft
}

.pulse {
  animation: pulse 1.5s infinite;
  animation-timing-function: linear;   
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1); }
  }