body {
  color: get-color('system');
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale; // optimize font in firefox
  -webkit-font-smoothing: antialiased; // apply font anti-aliasing
  text-rendering: optimizeLegibility; // firefox counts readability more than display speed
}

a {
  color: get-color('system', 'anchor');
  &.url-tel {
    color: get-color('system');
    cursor: default;
    text-decoration: underline;
  }

  &.link-disabled {
    pointer-events: none;
  }
}

.hidden {
  @include element-invisible;
}