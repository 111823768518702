.summary-box {
  @include v-space (margin-bottom, 1);
  border-radius: rem-calc(4);
  padding: rem-calc(20);
  @include breakpoint(medium) {
    padding: rem-calc(35);
  }
  ul {
    @include list-reset;
  }
  li {
    margin-bottom: 0;
  }
  .label,
  .value {
    display: inline-block;
    vertical-align: middle;
  }
  .label {
    font-size: rem-calc(12);
    width: 70%;
  }
  .value {
    text-align: right;
    width: 30%;
  }
}
