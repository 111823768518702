//integer select
// select number value
.integerselect {
  text-align: center;
  &__label {
    @include v-space(margin-bottom, 0.1);
    color: get-color('greyscale', 'grey2');
    font-size: rem-calc(14);
  }
  &__slider {
    &-button {
      @include transition;
      display: inline-block;
      font-size: rem-calc(20);
      padding: rem-calc(12);
      vertical-align: middle;
      &:hover {
        color: get-color('greyscale', 'grey2');
        cursor: pointer;
      }
    }
    &-gutter {
      @include border($radius: rem-calc(3));
      background-color: get-color('greyscale', 'white');
      display: inline-block;
      max-width: 330px;
      position: relative;
      overflow: hidden;
      vertical-align: middle;
      width: calc(100% - 90px);
    }
    &-value {
      border: 0;
      font-family: $baloo-paaji;
      font-size: 21px;
      line-height: rem-calc(44);
      text-align: center;
      width: 100%;
    }
    &-bar {
      background-color: get-color('primary');
      bottom: 0;
      height: rem-calc(4);
      left: 0;
      position: absolute;
      width: 50%;
    }
  }
  &__note {
    @extend .text__small;
    @include v-space(margin-top, 0.25);
  }
}
