.button-dropdown-container {
  .button-dropdown {
    width: 100%;
  }

  .button-dropdown-menu-container {
    position: relative;
    width: 100%;
    .button-dropdown-menu {
      border: solid 1px black;
      width: 100%;
      top: -15px;
      position: absolute;
      background-color: white; 
      .button-dropdown-item {
        &:hover {
          cursor: pointer;
        }
        padding: 1em;
        &.focused {
          background-color: get-color('primary', 'light')

        }
        &.selected {
          background-color: get-color('primary')
          
        }
      }
    }
  }
}