.alert {
  @include border;
  @include g-unit(padding, 1.5);
  @include v-space(margin-bottom, 0.5);
  @include v-space(margin-top, 0.5);
  @include grid-row;
  background-color: get-color('greyscale', 'white');
  @include breakpoint(medium) {
    @include g-unit(padding, 0.5);
  }
  &__confirm,
  &__fail,
  &__information,
  &__disable {
    position: relative;
    &::after {
      @include horizontal-center;
      content: '';
      background-color: get-color('greyscale', 'white');
      width: 10px;
      height: 10px;
      top: -4px;
      z-index: 0;
    }
  }
  &__confirm {
    background-color: #f0fff1;
    border-color: get-color('forms', 'success');
    .alert-icon {
      color: get-color('forms', 'success');
    }
  }
  &__fail {
    background-color: #fff0f0;
    border-color: get-color('forms', 'error');
    .alert-icon {
      color: get-color('forms', 'error');
    }
  }
  &__information {
    background-color: #fff6e6;
    border-color: get-color('forms', 'information');
    .alert-icon {
      color: get-color('forms', 'information');
    }
  }
  &__disable {
    .alert-icon {
      color: get-color('greyscale', 'grey5');
    }
  }

  .alert-icon {
    @include horizontal-center;
    background-color: transparent;
    top: -13px;
    z-index: 1;
    font-size: 20px;
  }
}
