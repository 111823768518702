table{
    width: 100%;
}
th{
    background-color: get-color('primary');
    font-family: $baloo-paaji;
}
td{
    font-family: $openSans;
}
ul {
    list-style-type:disc;
    font-family: $openSans;
}