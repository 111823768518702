.button {
  background-color: get-color('primary');
  border: 1px solid transparent;
  border-radius: $button-radius;
  box-sizing: border-box;
  color: get-color('system');
  cursor: pointer;
  display: block;
  font-size: map-get($button-sizes, default);
  padding: map-get($button-padding, default);
  margin: map-get($button-margin, default);
  font-weight: 600;
  line-height: 1.2;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  text-decoration: none;
  transition: background-color $transitionTime $transitionEase;
  @include breakpoint(medium) {
    line-height: 2;
  }
  &:hover {
    background-color: get-color('primary', 'light');
    cursor: pointer;
  }
  &:focus {
    @include focus;
  }
  &-secondary {
    box-sizing: border-box;
    background-color: get-color('greyscale', 'white');
    border-color: get-color('system');
    &:hover {
      background-color: get-color('primary', 'light');
      border-color: get-color('primary', 'light');
    }
  }
  &-tertiary, 
  &-tertiary-compact {
    background-color: transparent;
    color: get-color('greyscale', 'black');
    line-height: 1.5;
    i {
      color: get-color('greyscale', 'black');
      display: inline-block;
      margin: $icon-spacing;
      vertical-align: middle;
      &.icon-arrow-right {
        font-size: $icon-size-small;
      }
    }
    &:hover {
      background-color: inherit;
      text-decoration: underline;
    }
  }
  &-tertiary-compact {
    margin-bottom: 0;
  }
  &:disabled {
    background-color: get-color('greyscale', 'grey6');
    cursor: not-allowed;
  }
  &-secure {
    background-color: get-color('greyscale', 'black');
    color: get-color('greyscale', 'white');
    opacity: 1;
    &:hover {
      background-color: get-color('greyscale', 'grey1');
      opacity: 0.85;
      transition: $transitionTime $transitionEase;
    }
  }
  &--full {
    display: block;
    width: 100% !important;
  } // Sizes
  @each $size,
  $value in map-remove($button-sizes, default) {
    &--#{$size} {
      font-size: $value;
    }
  } // Paddings
  @each $size,
  $value in map-remove($button-padding, default) {
    &--#{$size} {
      padding: $value;
    }
  } // Margins
  @each $size,
  $value in map-remove($button-margin, default) {
    &--#{$size} {
      margin: $value;
    }
  }  
  // Icons
  &-loading {
    position: relative;
    i {
      @include object-absolute-centre(30px, 30px);
      stroke: get-color('system');
    }
  }
  i {
    display: inline-block;
    font-size: $icon-size;
    margin: $icon-spacing;
    vertical-align: middle;
  } //pill
  // selection pill states
  &__pill {
    &-group {
      text-align: center;
    }
    &-selection {
      @include transition;
      border: 1px solid get-color('forms', 'outline');
      border-radius: 30px;
      font-size: rem-calc(14);
      line-height: rem-calc(20);
      margin: rem-calc(10);
      padding: rem-calc(7) rem-calc(17) rem-calc(7) rem-calc(7);
      display: inline-block;
      i {
        float: left;
        font-size: rem-calc(20);
        margin-right: rem-calc(10);
        height: rem-calc(20);
      }
      &:hover {
        background-color: get-color('primary', 'light');
        border-color: get-color('primary', 'light');
        cursor: pointer;
      }
      &:focus {
        outline: none;
        border-color: get-color('forms', 'focus');
      }
    }
    &-remove {
      background-color: get-color('greyscale', 'grey7');
      color: get-color('greyscale', 'grey2');
    }
  }

  &__toggle {
    display: inline-block;
    &-group {
      text-align: center;
      display: inline-block;
      &.has-error {
        border-color:  get-color('forms', 'error');
        border-style: solid;
        border-width: 1px; 
      }
    }
    &.active {
      background-color: get-color('primary', 'light');
    }
  }
}

.button-group {
  .button {
    margin: 0;
    +.button {
      @include v-space(margin-left, 1);
    }
  }
}

.button--outside-canvas {
  @include v-space(margin-top, 1);
  display: block;
  width: 100%;
  
  @include breakpoint(medium) {
    max-width: 58.3333333333%;
    margin-left: auto;
    margin-right: auto;
  }
}

//Icons
//resize size of arrow Icons within buttons
.button {
  .icon-arrow-left,
  .icon-arrow-right {
    font-size: $icon-size-small;
    margin: $icon-spacing;
  }
}

.button-icon {
  color:  black;
  padding: 0.3em 0.5em;
  display: inline-block;
  cursor: pointer;
  border-radius: 50%;
  &.primary-background {
    background-color: get-color('primary', 'light');
  }
}