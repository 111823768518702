.personal-details-display {

  .personal-details-display-data {
    position: relative;

    .personal-details-edit-button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &.two-columns {

    .personal-details-display-data {
      display: grid;
      grid-template-columns: auto auto;
    }
  }

  &.box {
    .personal-details-display-data {
      background-color: get-color('greyscale', 'grey7');
      padding: 1em;
    }
  }

  .personal-details-display-row {
    
    &.address-details {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .personal-details-display-title {
      font-size: 0.9em;
      font-weight: bold;
    }
    .personal-details-display-value {
      margin-bottom: 0.7em;
    }
  }
}